import axios from "axios";
import Swal from "sweetalert2";

import {
  ChangeEvent,
  FocusEvent,
  FormEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Credentials3 } from "../inteface/data";

const base64 = require("base-64");
const utf8 = require("utf8");

const Auth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const months: Array<string> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekday: Array<string> = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [today, setTodayDate] = useState<string>(
    "Wednesday, 29 of September 2021 9:58.25PM"
  );

  const num1Ref = useRef<HTMLInputElement>(null);
  const num2Ref = useRef<HTMLInputElement>(null);
  const num3Ref = useRef<HTMLInputElement>(null);
  const num4Ref = useRef<HTMLInputElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);

  const [token] = useState<Credentials3>(location.state as Credentials3);
  const [loading, setLoading] = useState(false);
  const [enable, setEnabled] = useState(true);
  const [index, setIndex] = useState(1);
  const [count, setCount] = useState(59);

  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [code3, setCode3] = useState("");
  const [code4, setCode4] = useState("");

  const onOtpSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    if (token) {
      const payloadRequest = {
        id: token.userName,
        RID: token.RID,
        otp: (code1 + code2 + code3 + code4).toString(),
      };

      const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
      const payEncode: string = base64.encode(payloadUTF);
      const sigUTF = utf8.encode(token.signature);
      const sigEncoded: string = base64.encode(sigUTF);
      const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

      try {
        await axios
          .post(
            `https://${
              process.env.REACT_APP_ENDPOINT
            }/${process.env.REACT_APP_BUILD?.toLowerCase()}/topcheck`,
            payloadRequest,
            {
              headers: {
                "X-Signature": signature,
                "Content-Type": "application/json",
              },
              timeout: 0,
            }
          )
          .then((response) => {
            if (typeof response.data === "number" && response.data === 1) {
              localStorage.setItem("lang", token.lang || "CN");
              localStorage.setItem("userInfo", JSON.stringify(token));
              navigate("/profile");
            } else {
              Swal.fire({
                icon: "error",
                title: `${t("authentication_failed")}`,
                text: `${t("authentication_failed_message_1")}`,
                timer: 3000,
              });
              setLoading(false);
              resetFields();
            }
          })
          .catch((error: any) => {
            Swal.fire({
              icon: "error",
              title: `${t("authentication_failed")}`,
              text: `${t("authentication_failed_message_1")}`,
              timer: 3000,
            });
            setLoading(false);
            resetFields();
          });
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: `${t("authentication_failed")}`,
          text: `${t("authentication_failed_message_1")}`,
          timer: 3000,
        });
        setLoading(false);
        resetFields();
      }
    } else {
      Swal.fire({
        icon: "error",
        title: `${t("authentication_failed")}`,
        text: `${t("authentication_failed_message_1")}`,
        timer: 3000,
      });
      setLoading(false);
      resetFields();
    }
  };

  const callOtpSubmit = async (otp: string) => {
    setLoading(true);
    if (token) {
      try {
        console.info("callOtpSubmit ", otp);
        const payloadRequest = {
          id: token.userName,
          RID: token.RID,
          otp: otp.toString(),
        };
        const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
        const payEncode: string = base64.encode(payloadUTF);
        const sigUTF = utf8.encode(token.signature);
        const sigEncoded: string = base64.encode(sigUTF);
        const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

        await axios
          .post(
            `https://${
              process.env.REACT_APP_ENDPOINT
            }/${process.env.REACT_APP_BUILD?.toLowerCase()}/topcheck`,
            payloadRequest,
            {
              headers: {
                "X-Signature": signature,
                "Content-Type": "application/json",
              },
              timeout: 0,
            }
          )
          .then((response) => {
            if (typeof response.data === "number" && response.data === 1) {
              localStorage.setItem("lang", token.lang || "CN");
              localStorage.setItem("userInfo", JSON.stringify(token));
              navigate("/profile");
            } else {
              Swal.fire({
                icon: "error",
                title: `${t("authentication_failed")}`,
                text: `${t("authentication_failed_message_1")}`,
                timer: 3000,
              });
              setLoading(false);
              resetFields();
            }
          })
          .catch((error: any) => {
            Swal.fire({
              icon: "error",
              title: `${t("authentication_failed")}`,
              text: `${t("authentication_failed_message_1")}`,
              timer: 3000,
            });
            setLoading(false);
            resetFields();
          });
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: `${t("authentication_failed")}`,
          text: `${t("authentication_failed_message_1")}`,
          timer: 3000,
        });
        setLoading(false);
        resetFields();
      }
    } else {
      Swal.fire({
        icon: "error",
        title: `${t("authentication_failed")}`,
        text: `${t("authentication_failed_message_1")}`,
        timer: 3000,
      });
      setLoading(false);
      resetFields();
    }
  };

  const requestOtpCode = async (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    if (token) {
      try {
        const payloadRequest = {
          id: token.userName,
          RID: token.RID,
          otp: "resend",
        };
        const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
        const payEncode: string = base64.encode(payloadUTF);
        const sigUTF = utf8.encode(token.signature);
        const sigEncoded: string = base64.encode(sigUTF);
        const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

        await axios
          .post(
            `https://${
              process.env.REACT_APP_ENDPOINT
            }/${process.env.REACT_APP_BUILD?.toLowerCase()}/topcheck`,
            payloadRequest,
            {
              headers: {
                "X-Signature": signature,
                "Content-Type": "application/json",
              },
              timeout: 0,
            }
          )
          .then((response) => {
            if (typeof response.data === "number" && response.data === 1) {
              setCount(59);
            } else {
              Swal.fire({
                icon: "error",
                title: `${t("authentication_failed")}`,
                text: `${t("authentication_failed_message_2")}`,
                timer: 3000,
              }).then(() => {
                onPageReload();
              });
            }
          })
          .catch((error: any) => {
            Swal.fire({
              icon: "error",
              title: `${t("authentication_failed")}`,
              text: `${t("authentication_failed_message_2")}`,
              timer: 3000,
            }).then(() => {
              onPageReload();
            });
          });
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: `${t("authentication_failed")}`,
          text: `${t("authentication_failed_message_2")}`,
          timer: 3000,
        }).then(() => {
          onPageReload();
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: `${t("authentication_failed")}`,
        text: `${t("authentication_failed_message_2")}`,
        timer: 3000,
      }).then(() => {
        onPageReload();
      });
    }
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const otpNum: string = (
      (num1Ref.current?.value || "")?.toString() +
      (num2Ref.current?.value || "")?.toString() +
      (num3Ref.current?.value || "")?.toString() +
      (num4Ref.current?.value || "")?.toString()
    ).toString();
    if (otpNum.length >= 4) {
      callOtpSubmit(otpNum);
      //submitRef.current?.click();
      setEnabled(false);
    } else {
      setEnabled(true);
      if (e.target.value.length === 1) {
        switch (index) {
          case 1:
            num2Ref.current?.focus();
            break;
          case 2:
            num3Ref.current?.focus();
            break;
          case 3:
            num4Ref.current?.focus();
            break;
        }
      }
    }
  };

  const handleTextClick = (e: MouseEvent<HTMLInputElement>) => {
    if (
      num1Ref.current?.value.length &&
      num2Ref.current?.value.length &&
      num3Ref.current?.value.length &&
      num4Ref.current?.value.length
    ) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  };

  const handleTextFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (
      num1Ref.current?.value.length &&
      num2Ref.current?.value.length &&
      num3Ref.current?.value.length &&
      num4Ref.current?.value.length
    ) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  };

  const resetFields = () => {
    setCode1("");
    setCode2("");
    setCode3("");
    setCode4("");
  };

  const onPageReload = useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const startClock = () => {
    const interval = setInterval(() => {
      const objToday = new Date();
      const dayOfWeek = weekday[objToday.getDay()];
      const dayOfMonth = objToday.getDate();
      const curMonth = months[objToday.getMonth()];
      const curYear = objToday.getFullYear();
      let curHour: string | number = objToday.getHours();
      curHour =
        curHour > 12 ? curHour - 12 : curHour < 10 ? "0" + curHour : curHour;
      const curMinute =
        objToday.getMinutes() < 10
          ? "0" + objToday.getMinutes()
          : objToday.getMinutes();
      const curSeconds =
        objToday.getSeconds() < 10
          ? "0" + objToday.getSeconds()
          : objToday.getSeconds();
      const curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

      const updateToday = `${dayOfWeek}, ${dayOfMonth} of ${curMonth} ${curYear} ${curHour}:${curMinute}:${curSeconds}${curMeridiem}`;
      setTodayDate(updateToday);
    }, 1000);

    return interval;
  };

  useEffect(() => {
    const intervalId = startClock();
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    let interval = setInterval(() => {
      if (count > 0) {
        setCount((count) => count - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [count]);

  useEffect(() => {
    if (token === null || token === undefined) {
      const userInfo = localStorage.getItem("userInfo") || null;
      navigate(userInfo ? "/profile" : "/login");
    }
  }, [token, navigate]);

  useEffect(() => {
    num1Ref.current?.focus();
    window.onbeforeunload = onPageReload;
  }, [onPageReload]);

  return (
    <div>
      <div className="container position-sticky z-index-sticky top-0">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg blur border-radius-lg top-0 z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4">
              <div className="container-fluid ps-2 pe-0">
                <img
                  alt="logo"
                  className="navbar-brand-img font-weight-bolder ms-lg-0 ms-3 align-middle"
                  src="./assets/img/logo.png"
                  style={{ height: "35px" }}
                />
                <ul className="navbar-nav  d-flex align-items-center">
                  <li className="nav-item px-2">{today}</li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <main className="main-content  mt-0">
        <section>
          <div className="page-header min-vh-75">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                  <div className="card card-plain">
                    <div className="card-header text-center">
                      <h3 className="font-weight-bolder mb-0">
                        {t("verfication")}
                      </h3>
                    </div>
                    <div className="card-body px-lg-5 py-lg-5 text-center">
                      <p className="mt-0 mb-5">{t("otp_message_1")}</p>
                      <form onSubmit={onOtpSubmit}>
                        <div className="row gx-2 gx-sm-3 mb-4">
                          <div className="col">
                            <div className="input-group input-group-outline">
                              <input
                                type="number"
                                className="form-control form-control-lg text-center"
                                ref={num1Ref}
                                max={9}
                                required={true}
                                disabled={loading}
                                value={code1}
                                onChange={(e) => {
                                  if (e.target.value.length < 2) {
                                    setCode1(e.currentTarget.value);
                                    handleTextChange(e);
                                  }
                                }}
                                onClick={(e) => {
                                  setCode1("");
                                  handleTextClick(e);
                                }}
                                onFocus={(e) => {
                                  setIndex(1);
                                  handleTextFocus(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="input-group input-group-outline">
                              <input
                                id="numKey2"
                                type="number"
                                className="form-control form-control-lg text-center"
                                ref={num2Ref}
                                max={9}
                                required={true}
                                disabled={loading}
                                autoComplete="off"
                                autoCapitalize="off"
                                value={code2}
                                onChange={(e) => {
                                  if (e.target.value.length < 2) {
                                    setCode2(e.currentTarget.value);
                                    handleTextChange(e);
                                  }
                                }}
                                onClick={(e) => {
                                  setCode2("");
                                  handleTextClick(e);
                                }}
                                onFocus={(e) => {
                                  setIndex(2);
                                  handleTextFocus(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="input-group input-group-outline">
                              <input
                                id="numKey3"
                                type="number"
                                className="form-control form-control-lg text-center"
                                ref={num3Ref}
                                max={9}
                                required={true}
                                disabled={loading}
                                autoComplete="off"
                                autoCapitalize="off"
                                value={code3}
                                onChange={(e) => {
                                  if (e.target.value.length < 2) {
                                    setCode3(e.currentTarget.value);
                                    handleTextChange(e);
                                  }
                                }}
                                onClick={(e) => {
                                  setCode3("");
                                  handleTextClick(e);
                                }}
                                onFocus={(e) => {
                                  setIndex(3);
                                  handleTextFocus(e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="input-group input-group-outline">
                              <input
                                id="numKey4"
                                type="number"
                                className="form-control form-control-lg text-center"
                                ref={num4Ref}
                                max={9}
                                required={true}
                                disabled={loading}
                                autoComplete="off"
                                autoCapitalize="off"
                                value={code4}
                                onChange={(e) => {
                                  if (e.target.value.length < 2) {
                                    setCode4(e.currentTarget.value);
                                    handleTextChange(e);
                                  }
                                }}
                                onClick={(e) => {
                                  setCode4("");
                                  handleTextClick(e);
                                }}
                                onFocus={(e) => {
                                  setIndex(4);
                                  handleTextFocus(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            ref={submitRef}
                            disabled={enable}
                            className="btn bg-gradient-warning w-100 mb-4"
                          >
                            {loading && (
                              <span>
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                &nbsp;&nbsp;&nbsp;
                              </span>
                            )}
                            {t("send_code")}
                          </button>
                          <span className="text-muted text-sm">
                            {count > 0 ? (
                              <p>
                                <i className="fa fa-solid fa-clock me-md-1"></i>{" "}
                                &nbsp;
                                <span className="text-bold">
                                  {`0:${count > 9 ? count : "0" + count}`}
                                </span>
                              </p>
                            ) : (
                              <p>
                                {t("otp_message_2")} &nbsp;
                                <span
                                  className="btn btn-link text-warning text-bold"
                                  onClick={requestOtpCode}
                                >
                                  {t("otp_message_3")}
                                </span>
                              </p>
                            )}
                          </span>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                    <div
                      className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                      style={{
                        backgroundImage: `url(${"./assets/img/curved-images/curved5.jpg"})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto text-center mt-1 text-xxs">
              {`Version ${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_BUILD} `}
            </div>
            <div className="col-8 mx-auto text-center mt-1">
              <p className="mb-0 text-xs text-secondary">
                Copyright ©{" " + new Date().getFullYear() + " "}
                OGTING.com. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Auth;
