import React, { useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useTranslation } from "react-i18next";
type Props = {
  csvData: Object[];
  fileName: string;
  sheetName: string;
  loading: boolean;
};

export const ExportCSV2 = ({ csvData, fileName, sheetName, loading }: Props) => {
  const { t } = useTranslation();
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (csvData: Object[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(csvData);

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    
  }, [csvData])
  
  return (
    <button
      className="w-100 btn bg-gradient-warning btn-sm export mb-0 mt-sm-0 ms-1 mt-1"
      disabled={(csvData && csvData?.length>0)? false: true || loading}
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      {t("export")}
    </button>
  );
}

export default ExportCSV2;

