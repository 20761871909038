import React from "react";
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";

import Auth from "./views/Auth";
import Login from "./views/Login";
import Profile from "./views/Profile";

declare global {
  interface Window {
    initOptions(name:string): void;
  }
}

function App() {
 
  const resources = {
    EN: { //english
      translation: {
        "welcome": 'Welcome',
        "login_message": 'Enter your account number and password to sign in',
        "account": "Account Number",
        "username": "Username",
        "password": "Password",
        "rememberme": "Remember Me",
        "signin": "Sign In",
        "signout": "Sign Out",
        "verfication": "2-Step Verification",
        "otp_message_1": "Enter your verifcation code that we sent you through your phone number.",
        "otp_message_2": "Haven't received it?",
        "otp_message_3": "Resend a new code.",
        "send_code": "Send Code",
        "current_balance": "Current Balance",
        "balance": "Balance",
        "transaction_number": "Transaction Number",
        "transactions": "Transactions",
        "loans": "Loans",
        "total_loan": "Total Loan",
        "date": "Date",
        "hall": "Hall Name",
        "name": "Name",
        "amount": "Amount",
        "loan_id": "Loan Number",
        "handler": "Handler",
        "remarks": "Remarks",
        "loading": "Loading",
        "loading_message": "Please wait...",
        "error": "Error",
        "error_message": "Account does not exist...",
        "login_failed": "Login Failed",
        "login_failed_message": "Incorrect Account Number or Password",
        "authentication_failed": "Authentication Failed",
        "authentication_failed_message_1": "Invalid OTP",
        "authentication_failed_message_2": "Request OTP Failed",
        "session_expired": "Session Expired",
        "session_message": "You session has ended. Please sign in again",
        "table_message": "Click to show more details",
        "details": "Details",
        "language": "Language",
        "settings": "Settings",
        "change-password": "Changed Password",
        "update-password": "Update Password",
        "verify-password": "Verify Password",
        "cancel": "Cancel",
        "save": "Save",
        "refresh": "Refresh",
        "export": "Export",
        "no-entries": "No Entries",
        "go-to-page": "Go to page",
        "showing": "Showing",
        "from": "From",
        "to": "to",
        "of": "of",
        "entries": "entries",
      }
    },
    ZH: { //Simplified
      translation: {
        "welcome": '歡迎',
        "login_message": '輸入您的帳號和密碼以登錄',
        "account": "帳號",
        "username": "户名",
        "password": "密碼",
        "rememberme": "記得我",
        "signin": "登入",
        "signout": "登出",
        "verfication": "兩步驗證",
        "otp_message_1": "輸入我們通過您的電話號碼發送給您的驗證碼。",
        "otp_message_2": "還沒收到？",
        "otp_message_3": "重新發送新代碼。",
        "send_code": "發送代碼",
        "current_balance": "當前餘額",
        "balance": "平衡",
        "transaction_number": "交易號",
        "transactions": "交易",
        "loans": "貸款",
        "total_loan": "總貸款",
        "date": "日期",
        "hall": "大廳",
        "name": "姓名",
        "amount": "數量",
        "loan_id": "貸款號碼",
        "handler": "處理程序",
        "remarks": "評論",
        "loading": "正在加載",
        "loading_message": "請稍等...",
        "error": "錯誤",
        "error_message": "帳號不存在...",
        "login_failed": "登錄失敗",
        "login_failed_message": "帳號或密碼不正確",
        "authentication_failed": "身份驗證失敗",
        "authentication_failed_message_1": "無效的一次性密碼",
        "authentication_failed_message_2": "請求 OTP 失敗",
        "session_expired": "會話已過期",
        "session_message": "您的會話已結束。 請重新登錄",
        "table_message": "點擊顯示更多詳情",
        "details": "細節",
        "language": "语",
        "settings": "Settings",
        "change-password": "Changed Password",
        "update-password": "更新密码",
        "verify-password": "验证密码",
        "cancel": "取消",
        "save": "节省",
        "refresh": "重新加载",
        "export": "导出",
        "no-entries": "无条目",
        "go-to-page": "转到页面",
        "showing": "显示",
        "from": "From",
        "to": "至",
        "of": "of",
        "entries": "个条目",
      }
    },
    CN: { //Traditional
      translation: {
        "welcome": '欢迎',
        "login_message": '输入您的帐号和密码以登录',
        "account": "帐号",
        "username": "戶名",
        "password": "密码",
        "rememberme": "记得我",
        "signin": "登入",
        "signout": "登出",
        "verfication": "两步验证",
        "otp_message_1": "输入我们通过您的电话号码发送给您的验证码。",
        "otp_message_2": "还没收到？",
        "otp_message_3": "重新发送新代码。",
        "send_code": "发送代码",
        "current_balance": "当前余额",
        "balance": "平衡",
        "transaction_number": "交易号",
        "transactions": "交易",
        "loans": "贷款",
        "total_loan": "总贷款",
        "date": "日期",
        "hall": "大厅",
        "name": "姓名",
        "amount": "数量",
        "loan_id": "贷款号码",
        "handler": "处理程序",
        "remarks": "评论",
        "loading": "正在加载",
        "loading_message": "请稍等...",
        "error": "错误",
        "error_message": "帐号不存在...",
        "login_failed": "登录失败",
        "login_failed_message": "帐号或密码不正确",
        "authentication_failed": "身份验证失败",
        "authentication_failed_message_1": "一次性密码无效",
        "authentication_failed_message_2": "请求 OTP 失败",
        "session_expired": "会话已过期",
        "session_message": "您的会话已结束。 请重新登录",
        "table_message": "点击显示更多详情",
        "details": "细节",
        "language": "語",
        "settings": "Settings",
        "change-password": "Changed Password",
        "update-password": "更新密碼",
        "verify-password": "驗證密碼",
        "cancel": "取消",
        "save": "節省",
        "refresh": "重新加載",
        "export": "導出",
      }
    },
    KR: { //Korean
      translation: {
        "welcome": '어서 오십시오',
        "login_message": '계정 번호와 비밀번호를 입력하여 로그인하세요',
        "account": "계좌 번호",
        "username": "회원 아이디",
        "password": "비밀번호",
        "rememberme": "날 기억해",
        "signin": "로그인",
        "signout": "로그아웃",
        "verfication": "2단계 인증",
        "otp_message_1": "전화번호로 보내드린 인증 코드를 입력하세요.",
        "otp_message_2": "받지 못하셨나요?",
        "otp_message_3": "새 코드를 다시 보내십시오.",
        "send_code": "코드 보내기",
        "current_balance": "현재의 균형",
        "balance": "균형",
        "transaction_number": "거래 번호",
        "transactions": "업무",
        "loans": "대출",
        "total_loan": "총 대출",
        "date": "날짜",
        "hall": "홀",
        "name": "이름",
        "amount": "양",
        "loan_id": "대출 번호",
        "handler": "매니저",
        "remarks": "비고",
        "loading": "로딩 중",
        "loading_message": "기다리세요...",
        "error": "오류",
        "error_message": "계정이 존재하지 않습니다...",
        "login_failed": "로그인 실패",
        "login_failed_message": "잘못된 계좌 번호 또는 비밀번호",
        "authentication_failed": "인증 실패",
        "authentication_failed_message_1": "잘못된 OTP",
        "authentication_failed_message_2": "OTP 요청 실패",
        "session_expired": "세션 만료됨",
        "session_message": "세션이 종료되었습니다. 다시 로그인하십시오",
        "table_message": "자세한 내용을 보려면 클릭하십시오",
        "details": "세부",
        "language": "Language",
        "settings": "Settings",
        "change-password": "Changed Password",
        "update-password": "Update Password",
        "verify-password": "Verify Password",
        "cancel": "Cancel",
        "save": "Save",
        "refresh": "Refresh",
        "export": "Export",
        "no-entries": "無條目",
        "go-to-page": "轉到頁面",
        "showing": "顯示",
        "from": "From",
        "to": "至",
        "of": "of",
        "entries": "條目",
      }
    }
  };

  const defaultLang:string = localStorage.getItem('lang') || 'CN'; //navigator.language.replace(/-[a-zA-Z]{2}/, "").toUpperCase() || 'CN'
  i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLang, 
    interpolation: {
      escapeValue: false
    }
  });
  
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
