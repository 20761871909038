import axios from "axios";
import i18n from "i18next";
import numeral from "numeral";
import Swal from "sweetalert2";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Credentials3, Guest, Loans, Transaction } from "../inteface/data";

import TableFilter from "./components/datatable/TableFilter";
import ExportCSV from "./utils/ExportCSV";
import ExportCSV2 from "./utils/ExportCSV2";

const base64 = require("base-64");
const utf8 = require("utf8");

interface IUserList {
  accountName: string;
  accountNumber: string;
}

const Profile = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const cancelButton = useRef<HTMLButtonElement>(null);
  const modalButton = useRef<HTMLButtonElement>(null);

  const [groupUserList, setGroupUserList] = useState<Array<IUserList>>([]);

  const [transactions, setTransactions] = useState<Array<Transaction>>([]);

  const [loans, setLoans] = useState<Array<Loans>>([]);
  const [profile] = useState<Guest | undefined>(undefined);
  const [ticker, setTicker] = useState<any | undefined>(undefined);
  const [detail, setDetails] = useState<any | undefined>(undefined);

  const [lang, setLang] = useState<string>(i18n.language || "CN");
  const [prevhall, setPrevHall] = useState<string>("OG東方會PHP");
  const [hall, setHall] = useState<string>("OG東方會PHP");
  const [account, setAccount] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const [verifyPass, setVerifyPass] = useState<string>("");
  const [newPass, setNewPass] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);

  const [transactionPerPage, setTransactionPerPage] = useState<number>(30);
  const [prevTransactionPerPage, setPrevTransactionPerPage] =
    useState<number>(30);

  const transactionsColumns = [
    {
      Header: "Guest List",
      columns: [
        {
          Header: `${t("date")}`,
          accessor: "DateTimeAdded",
          filter: "text",
        },
        {
          Header: `${t("hall")}`,
          accessor: "OfficeName",
          filter: "globalFilter",
        },
        {
          Header: `${t("amount")}`,
          accessor: "TransactionAmount",
          filter: "text",
        },
        {
          Header: `${t("balance")}`,
          accessor: "Balance",
          filter: "text",
        },
        {
          Header: `${t("remarks")}`,
          accessor: "Remark",
          filter: "text",
        },
      ],
    },
  ];

  const loanColumns = [
    {
      Header: "Guest List",
      columns: [
        {
          Header: `${t("date")}`,
          accessor: "LoanDate",
          filter: "text",
        },
        {
          Header: `${t("hall")}`,
          accessor: "HallName",
          filter: "globalFilter",
        },
        {
          Header: `${t("account")}`,
          accessor: "AccountNumber",
          filter: "text",
        },
        {
          Header: `${t("loan_id")}`,
          accessor: "LoanNumber",
          filter: "text",
        },
        {
          Header: `${t("amount")}`,
          accessor: "LoanAmount",
          filter: "text",
        },
      ],
    },
  ];

  const GetHallList = ticker?.Hall?.map((hall: any, index: number) => (
    <option key={index} value={hall.OfficeName}>
      {hall.OfficeName}
    </option>
  ));

  const GetGroupUserOptions = groupUserList?.map(
    (value: any, index: number) => (
      <option className="py-2" key={index} value={value.accountNumber}>
        {value.accountNumber}
      </option>
    )
  );

  const isStringObject = (string: string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  const fetchDetails = async (userInfo: Credentials3, account: string) => {
    try {
      setTicker(undefined);

      const payloadRequest = {
        RID: userInfo.RID.toString(),
        Hall: hall.toString(),
        AccountNumber: account.toString(),
        FMUser: userInfo.userName,
      };
      const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
      const payEncode: string = base64.encode(payloadUTF);
      const sigUTF = utf8.encode(userInfo.signature);
      const sigEncoded: string = base64.encode(sigUTF);
      const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

      await axios
        .post(
          `https://${
            process.env.REACT_APP_ENDPOINT
          }/${process.env.REACT_APP_BUILD?.toLowerCase()}/web-get-account-totals`,
          payloadRequest,
          {
            headers: {
              "X-Signature": signature,
              "Content-Type": "application/json",
            },
            timeout: 0,
          }
        )
        .then((response) => {
          if (
            typeof response.data === "object" &&
            response.data.hasOwnProperty("statusCode")
          ) {
            onLogout();
          } else if (
            typeof response.data === "string" &&
            isStringObject(response.data)
          ) {
            const result = JSON.parse(response.data);
            if (!result.hasOwnProperty("errorMessage")) {
              setTicker(result);
            } else {
              setTicker(undefined);
            }
          } else {
            setTicker(undefined);
          }
        })
        .catch((error: any) => {
          setTicker(undefined);
        });
    } catch (error: any) {
      setTicker(undefined);
    }
  };

  const fetchTransactions = async (userInfo: any, account: string) => {
    try {
      setTransactions([]);

      const payloadRequest = {
        RID: userInfo.RID.toString(),
        Hall: hall.toString(),
        FMUser: userInfo.userName,
        AccountNumber: account.toString(),
        Range: transactionPerPage.toString(),
      };
      const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
      const payEncode: string = base64.encode(payloadUTF);
      const sigUTF = utf8.encode(userInfo.signature);
      const sigEncoded: string = base64.encode(sigUTF);
      const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

      // fm-customertransactions-all // web-customertransactions
      await axios
        .post(
          `https://${
            process.env.REACT_APP_ENDPOINT
          }/${process.env.REACT_APP_BUILD?.toLowerCase()}/fm-customertransactions-all`,
          payloadRequest,
          {
            headers: {
              "X-Signature": signature,
              "Content-Type": "application/json",
            },
            timeout: 0,
          }
        )
        .then((response) => {
          if (
            typeof response.data === "object" &&
            response.data.hasOwnProperty("statusCode")
          ) {
            onLogout();
          } else if (Array.isArray(response.data)) {
            const result: Array<Transaction> = response.data;
            setTransactions(result);
          } else {
            setTransactions([]);
          }
        })
        .catch((error: any) => {
          setTransactions([]);
        });
    } catch (error: any) {
      setTransactions([]);
    }
  };

  const fetchLoans = async (userInfo: any, account: string) => {
    try {
      setLoans([]);

      const payloadRequest = {
        RID: userInfo.RID.toString(),
        Hall: hall.toString(),
        AccountNumber: account.toString(),
        FMUser: userInfo.userName,
      };
      const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
      const payEncode: string = base64.encode(payloadUTF);
      const sigUTF = utf8.encode(userInfo.signature);
      const sigEncoded: string = base64.encode(sigUTF);
      const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

      await axios
        .post(
          `https://${
            process.env.REACT_APP_ENDPOINT
          }/${process.env.REACT_APP_BUILD?.toLowerCase()}/web-cloans`,
          payloadRequest,
          {
            headers: {
              "X-Signature": signature,
              "Content-Type": "application/json",
            },
            timeout: 0,
          }
        )
        .then((response) => {
          if (
            typeof response.data === "object" &&
            response.data.hasOwnProperty("statusCode")
          ) {
            onLogout();
          } else if (Array.isArray(response.data)) {
            const result: Array<Loans> = response.data;
            setLoans(result);
          } else {
            setLoans([]);
          }
        })
        .catch((error: any) => setLoans([]));
    } catch (error: any) {
      setLoans([]);
    }
  };

  const fetchGroupUserList = async (userInfo: Credentials3) => {
    if (loading) return;

    setLoading(true);
    setGroupUserList([]);

    const payloadRequest = {
      RID: userInfo.RID.toString(),
      FMGroup: userInfo.FMGroup,
      FMUser: userInfo.userName,
    };
    const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
    const payEncode: string = base64.encode(payloadUTF);
    const sigUTF = utf8.encode(userInfo.signature);
    const sigEncoded: string = base64.encode(sigUTF);
    const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

    await axios
      .post(
        `https://${
          process.env.REACT_APP_ENDPOINT
        }/${process.env.REACT_APP_BUILD?.toLowerCase()}/fmgroup-account-list-get`,
        payloadRequest,
        {
          headers: {
            "X-Signature": signature,
            "Content-Type": "application/json",
          },
          timeout: 0,
        }
      )
      .then((response) => {
        if (typeof response.data === "object" && response.data !== null) {
          const result: Array<IUserList> = response.data;
          setGroupUserList(result);
        } else {
          setGroupUserList([]);
          Swal.fire({
            position: "center",
            icon: "info",
            title: "Get Group User List Failed",
            text: "Error: " + response.data.toString(),
            showConfirmButton: true,
            allowEnterKey: false,
            allowOutsideClick: false,
          }).then(async (result) => {
            onLogout();
          });
        }
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
      });
  };

  const updatePassword = async () => {
    setLoading(true);
    const userInfo: Credentials3 = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : undefined;

    const payloadRequest = {
      RID: userInfo?.RID,
      FMUser: userInfo.userName,
      UserName: userInfo?.userName,
      Password: newPass.toString(),
      ConfirmPassword: verifyPass.toString(),
    };
    const payloadUTF = utf8.encode(JSON.stringify(payloadRequest));
    const payEncode: string = base64.encode(payloadUTF);
    const sigUTF = utf8.encode(userInfo.signature);
    const sigEncoded: string = base64.encode(sigUTF);
    const signature: string = base64.encode(`${payEncode}.${sigEncoded}`);

    await axios
      .post(
        `https://${
          process.env.REACT_APP_ENDPOINT
        }/${process.env.REACT_APP_BUILD?.toLowerCase()}/setepw`,
        payloadRequest,
        {
          headers: {
            "X-Signature": signature,
            "Content-Type": "application/json",
          },
          timeout: 0,
        }
      )
      .then((response) => {
        if (typeof response.data === "number" && Number(response.data) === 1) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Update Password Successful",
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 3000,
            didOpen: () => {
              setLoading(false);
            },
          }).then(async (result) => {
            Swal.close();
            cancelButton.current?.click();
            onLogout();
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Update Password Failed",
            text: `Error: ${
              response.data.hasOwnProperty("errorMessage")
                ? response.data.errorMessage
                : response.data
            }`,
            showConfirmButton: true,
            allowEnterKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              setLoading(false);
              setNewPass("");
              setVerifyPass("");
            },
          }).then(async (result) => {
            onLogout();
          });
        }
      })
      .catch((error: any) => {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Update Password Failed",
          text: `Request Failed (${
            error.response && error.response.data.message
              ? error.data.message
              : error.message
          })`,
          showConfirmButton: true,
          allowEnterKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            setLoading(false);
          },
        });
      });
  };

  const setLanguage = (locale: string) => {
    localStorage.setItem("lang", locale);
    i18n.changeLanguage(locale);
    setLang(locale);
  };

  const reloadTransaction = () => {
    const userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : undefined;
    fetchTransactions(userInfo, account);
  };

  const reloadLoans = () => {
    const userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : undefined;
    fetchLoans(userInfo, account);
  };

  const onLogout = () => {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("lang");
    navigate("/login");
  };

  useEffect(() => {
    if (transactionPerPage !== prevTransactionPerPage) {
      setPrevTransactionPerPage(transactionPerPage);
      (async () => {
        const userInfo: Credentials3 = localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")!)
          : undefined;

        if (userInfo && account !== "") {
          await fetchTransactions(userInfo, account);
          setLoading(false);
        }
      })();
    }
  }, [transactionPerPage]);

  useEffect(() => {
    if (hall !== "") {
      if (hall !== prevhall) {
        Swal.fire({
          title: `${t("loading")}`,
          text: `${t("loading_message")}`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const userInfo = localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")!)
          : undefined;

        (async () => {
          setLoading(true);
          await Promise.all([
            fetchDetails(userInfo, account),
            fetchTransactions(userInfo, account),
            fetchLoans(userInfo, account),
          ]);
          setPrevHall(hall);
          setLoading(false);
          Swal.close();
        })();
      }
    }
  }, [hall]);

  useEffect(() => {
    let timeout: any;

    const body = document.getElementsByTagName("body")[0];
    body.setAttribute("className", "g-sidenav-show bg-gray-100");

    const userInfo: Credentials3 = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : undefined;

    if (userInfo) {
      const expireDate: string = userInfo.expires_at.toString();
      const dateTime = expireDate.split(" ");
      const dateExpiry: Array<string> = dateTime[0].split("-");
      const timeExpiry: Array<string> = dateTime[1].split(":");
      const duration: number = new Date(
        parseInt(dateExpiry[0]),
        parseInt(dateExpiry[1]),
        parseInt(dateExpiry[2]),
        parseInt(timeExpiry[0]),
        parseInt(timeExpiry[1]),
        parseInt(timeExpiry[2])
      ).getTime();

      const expiry = duration - new Date().getTime();

      if (expiry > 0) {
        Swal.fire({
          title: `${t("loading")}`,
          text: `${t("loading_message")}`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        timeout = setTimeout(() => {
          Swal.fire({
            icon: "warning",
            title: `${t("session_expired")}`,
            text: `${t("session_message")}`,
            showConfirmButton: false,
            timer: 3000,
            didOpen: () => {
              Swal.hideLoading();
            },
            allowOutsideClick: false,
          }).then(onLogout);
        }, expiry / 1000);

        (async () => {
          await Promise.all([fetchGroupUserList(userInfo)]);
          setLoading(false);
          Swal.close();
        })();
      } else {
        Swal.fire({
          icon: "error",
          title: `${t("session_expired")}`,
          text: `${t("session_message")}`,
          showConfirmButton: false,
          timer: 3000,
          didOpen: () => {
            Swal.hideLoading();
          },
          allowOutsideClick: false,
        }).then(onLogout);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: `${t("error")}`,
        text: `${t("error_message")}`,
        showConfirmButton: false,
        timer: 3000,
        didOpen: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
      }).then(onLogout);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (account !== "") {
      (async () => {
        Swal.fire({
          title: `${t("loading")}`,
          text: `${t("loading_message")}`,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const userInfo: Credentials3 = localStorage.getItem("userInfo")
          ? JSON.parse(localStorage.getItem("userInfo")!)
          : undefined;

        if (userInfo && account !== "") {
          await Promise.all([
            fetchDetails(userInfo, account),
            fetchTransactions(userInfo, account),
            fetchLoans(userInfo, account),
          ]);
          setLoading(false);
          Swal.close();
        }
      })();
    } else {
      setTicker(undefined);
      setTransactions([]);
      setLoans([]);
    }
  }, [account]);

  const showDetails = (data: any) => {
    setDetails(data);
    modalButton?.current?.click();
  };

  return (
    <>
      <div className="main-content position-relative bg-gray-100 max-height-vh-100 h-100">
        <nav className="navbar navbar-main navbar-expand-lg bg-transparent shadow-none position-absolute px-4 w-100 z-index-2">
          <div className="container-fluid py-1">
            <div
              className="collapse navbar-collapse me-md-0 me-sm-4 mt-sm-0 mt-2 show"
              id="navbar"
            >
              <ul className="ms-md-auto navbar-nav justify-content-end">
                <li className="nav-item d-flex align-items-center">
                  <button
                    className="btn btn-link nav-link text-white font-weight-bold px-0 d-none d-sm-block"
                    onClick={onLogout}
                  >
                    <i className="fa fa-user me-sm-1"></i>
                    &nbsp;
                    <span className="d-sm-inline">{t("signout")}</span>
                  </button>
                  &nbsp;&nbsp;&nbsp;
                </li>
                <li className="nav-item d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-link nav-link text-white font-weight-bold px-0"
                    data-bs-toggle="modal"
                    data-bs-target="#settingsModal"
                  >
                    <i className="fas fa-cog me-sm-1"></i>
                    &nbsp;
                    <span className="d-sm-inline">{t("settings")}</span>
                  </button>
                  &nbsp;&nbsp;&nbsp;
                </li>

                <li className="nav-item dropdown pe-2 d-flex align-items-center">
                  <button
                    className="btn btn-link nav-link text-white p-0"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      alt="flag"
                      src={`../assets/img/icons/${lang}.svg`}
                      className="avatar avatar-sm bg-white rounded-circle mx-auto d-block border"
                    />
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end px-2 py-3 ms-n4"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li className="mb-2">
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("EN")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="english-flag"
                              src="../assets/img/icons/EN.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                              style={{ width: "100%", height: "auto" }}
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              <span className="font-weight-bold">English</span>
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li className="mb-2">
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("ZH")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="chinese-traditional-flag"
                              src="../assets/img/icons/ZH.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              中国 (Traditional)
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li className="mb-2">
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("CN")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="chinese-simplified-flag"
                              src="../assets/img/icons/CN.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              中国 (Simplified)
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <button
                        className="btn btn-link dropdown-item border-radius-md"
                        onClick={() => setLanguage("KR")}
                      >
                        <div className="d-flex py-1">
                          <div className="my-auto">
                            <img
                              alt="korean-flag"
                              src="../assets/img/icons/KR.svg"
                              className="avatar avatar-sm rounded-circle bg-white me-3 border"
                            />
                          </div>
                          <div className="d-flex flex-column justify-content-center">
                            <h6 className="text-sm font-weight-normal mb-1">
                              Korean
                            </h6>
                          </div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="container-fluid mb-4">
          <div
            className="page-header min-height-150 border-radius-xl mt-4 "
            style={{
              backgroundColor: "orange",
              backgroundImage: `url(${"../assets/img/curved-images/curved5.jpg"})`,
              backgroundSize: "cover",
            }}
          >
            <span className="mask bg-gradient-warning opacity-6"></span>
          </div>
          <div className="card card-body blur shadow-blur mx-4 mt-n6 overflow-hiddenCols">
            <div className="row gx-4">
              <div className="col-auto">
                <div className="avatar avatar-xl position-relative">
                  <img
                    src="../assets/img/profile.png"
                    alt="profile_image"
                    className="w-100 border-radius-lg shadow-sm"
                  />
                </div>
              </div>
              <div className="col-auto my-auto">
                <div className="h-100">
                  <h2 className="mb-1 text-uppercase">
                    {account || t("account")}
                  </h2>
                  <p className="mb-1 font-weight-bold text-xs">{hall}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid d-block d-sm-none my-2">
          <button
            type="button"
            className="btn bg-gradient-danger w-100 mb-0"
            onClick={onLogout}
          >
            {t("signout")}
          </button>
        </div>

        <div className="container-fluid mt-2">
          <div className="row d-flex text-center mb-4">
            <div className="mt-2 col-12 col-md-4 col-sm-4">
              <h6 className="text-xs mb-2 pb-0 font-weight-bolder mb-0">
                {t("account")}
              </h6>
              <select
                className="form-control form-control-xl text-center"
                id="choices-hall"
                name="choices-hall"
                required={true}
                disabled={loading}
                value={account || "Select Account"}
                onChange={(e) => setAccount(e.currentTarget.value)}
              >
                <option key={-1} value="">
                  Select Account
                </option>
                {GetGroupUserOptions}
              </select>
            </div>
            <div className="mt-2 col-6 col-md-4 col-sm-4">
              <h6 className="text-xs mb-2 pb-0 font-weight-bolder mb-0">
                Transaction Days
              </h6>
              <select
                className="form-control form-control-xl text-center"
                id="choices-hall"
                name="choices-hall"
                required={true}
                disabled={loading}
                value={transactionPerPage || "Hall Name"}
                onChange={(e) => setTransactionPerPage(Number(e.target.value))}
              >
                {[30, 60, 90].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-2 col-6 col-md-4 col-sm-4">
              <h6 className="text-xs mb-2 pb-0 font-weight-bolder mb-0">
                {t("hall")}
              </h6>
              <select
                className="form-control form-control-xl text-center"
                id="choices-hall"
                name="choices-hall"
                required={true}
                disabled={loading}
                value={hall || "Hall Name"}
                onChange={(e) => setHall(e.currentTarget.value)}
              >
                {!ticker && (
                  <option key={-1} value="OG東方會PHP">
                    OG東方會PHP
                  </option>
                )}
                {GetHallList}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-sm-6 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold">
                          {t("current_balance")}
                        </p>
                        <h2 className="text-gradient text-warning font-weight-bolder mb-0">
                          {hall.substr(hall.length - 3, hall.length) || ""}
                          &nbsp;
                          {numeral(ticker?.Balance).format("0,0.[00000]") ||
                            "0.00"}
                        </h2>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-warning shadow text-center border-radius-md">
                        <i
                          className="fs-4 ni ni-money-coins opacity-10"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-sm-6 mb-4">
              <div className="card">
                <div className="card-body p-3">
                  <div className="row">
                    <div className="col-8">
                      <div className="numbers">
                        <p className="text-sm mb-0 text-capitalize font-weight-bold">
                          {t("total_loan")}
                        </p>
                        <h2 className="text-gradient text-warning font-weight-bolder mb-0">
                          {hall.substr(hall.length - 3, hall.length) || ""}
                          &nbsp;
                          {numeral(ticker?.Loan).format("0,0.[00000]") ||
                            "0.00"}
                        </h2>
                      </div>
                    </div>
                    <div className="col-4 text-end">
                      <div className="icon icon-shape bg-gradient-warning shadow text-center border-radius-md">
                        <i
                          className="fs-4 fas fa-hand-holding-usd opacity-10"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header pb-0 p-3">
                  <div className="d-flex">
                    <h6 className="mb-1">{t("transactions")}</h6>
                    <div className="ms-auto my-auto mt-lg-0 mt-4 d-none d-sm-block">
                      <div className="d-flex ms-auto my-auto">
                        <button
                          className="w-100 btn bg-gradient-warning btn-sm export mb-0 mt-sm-0 ms-1 mt-1"
                          disabled={
                            transactions && transactions?.length > 0
                              ? false
                              : true || loading
                          }
                          onClick={reloadTransaction}
                        >
                          {t("refresh")}
                        </button>
                        <ExportCSV
                          loading={loading}
                          csvData={transactions}
                          fileName="transactions-reports"
                          sheetName="transactions"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-xs mb-0 pb-0 d-sm-block d-md-none d-block d-sm-none">
                    {t("table_message")}
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="row d-sm-none d-md-block d-none d-sm-block">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          columns={transactionsColumns}
                          data={transactions || []}
                          hiddenCols={["Account"]}
                          filters={[]}
                          loading={false}
                        />
                      }
                    </div>
                  </div>
                  <div className="row d-sm-block d-md-none d-block d-sm-none">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          name={"transactions"}
                          columns={transactionsColumns}
                          data={transactions || []}
                          hiddenCols={["OfficeName", "Account"]}
                          filters={[]}
                          loading={false}
                          callback={showDetails}
                        />
                      }
                    </div>
                    <div className="d-flex d-block d-sm-none">
                      <div className="col mt-2 me-1">
                        <button
                          className="w-100 btn bg-gradient-warning btn-sm export mb-0 mt-sm-0 ms-1 mt-1"
                          disabled={
                            transactions && transactions?.length > 0
                              ? false
                              : true || loading
                          }
                          onClick={reloadTransaction}
                        >
                          {t("refresh")}
                        </button>
                      </div>
                      <div className="col mt-2 ms-1">
                        <ExportCSV2
                          loading={loading}
                          csvData={transactions}
                          fileName="transactions-reports"
                          sheetName="transactions"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-4">
              <div className="card mb-4">
                <div className="card-header pb-0 p-3">
                  <div className="d-flex">
                    <h6 className="mb-1">{t("loans")}</h6>

                    <div className="ms-auto my-auto mt-lg-0 mt-4  d-none d-sm-block">
                      <div className="d-flex ms-auto my-auto">
                        <button
                          className="w-100 btn bg-gradient-warning btn-sm export mb-0 mt-sm-0 ms-1 mt-1"
                          disabled={
                            loans && loans?.length > 0 ? false : true || loading
                          }
                          onClick={reloadLoans}
                        >
                          {t("refresh")}
                        </button>
                        <ExportCSV
                          loading={loading}
                          csvData={loans}
                          fileName="loans-reports"
                          sheetName="loans"
                        />
                      </div>
                    </div>
                  </div>
                  <p className="text-xs mb-0 pb-0 d-sm-block d-md-none d-block d-sm-none">
                    {t("table_message")}
                  </p>
                </div>
                <div className="card-body p-3">
                  <div className="row d-sm-none d-md-block d-none d-sm-block">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          columns={loanColumns}
                          data={loans || []}
                          hiddenCols={[]}
                          filters={[]}
                          loading={false}
                        />
                      }
                    </div>
                  </div>
                  <div className="row d-sm-block d-md-none d-block d-sm-none">
                    <div className="table-responsive p-0">
                      {
                        <TableFilter
                          name={"loans"}
                          columns={loanColumns}
                          data={loans || []}
                          hiddenCols={["HallName", "AccountNumber"]}
                          filters={[]}
                          loading={false}
                          callback={showDetails}
                        />
                      }
                    </div>
                    <div className="d-flex d-block d-sm-none">
                      <div className="col mt-2 me-1">
                        <button
                          className="w-100 btn bg-gradient-warning btn-sm export mb-0 mt-sm-0 ms-1 mt-1"
                          disabled={
                            loans && loans?.length > 0 ? false : true || loading
                          }
                          onClick={reloadLoans}
                        >
                          {t("export")}
                        </button>
                      </div>
                      <div className="col mt-2 ms-1">
                        <ExportCSV2
                          loading={loading}
                          csvData={loans}
                          fileName="loan-reports"
                          sheetName="loan"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`modal mt-4 fade`}
              id="detailsModal"
              tabIndex={-1}
              role="dialog"
              aria-labelledby="detailsModal"
              aria-hidden={detail ? false : true}
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title font-weight-normal"
                      id="detailsModalLabel"
                    >
                      {t(`${detail?.type}` || "details")}
                    </h5>
                    <button
                      type="button"
                      className="btn-close text-dark"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setDetails(undefined)}
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {detail?.type === "transactions" && (
                      <p className="mx-4 my-4">
                        <span className="mx-2 font-weight-bolder">
                          {t("date")}:
                        </span>{" "}
                        {detail?.row.DateTimeAdded || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("hall")}:
                        </span>{" "}
                        {detail?.row.OfficeName || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("amount")}:
                        </span>{" "}
                        {numeral(detail?.row.TransactionAmount).format(
                          "0,0.[00000]"
                        ) || ""}
                        <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("balance")}:
                        </span>{" "}
                        {numeral(detail?.row.Balance).format("0,0.[00000]") ||
                          ""}
                        <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("remarks")}:
                        </span>{" "}
                        {detail?.row.Remark || ""} <br />
                      </p>
                    )}

                    {detail?.type === "loans" && (
                      <p className="mx-4 my-4">
                        <span className="mx-2 font-weight-bolder">
                          {t("date")}:
                        </span>{" "}
                        {detail?.row.LoanDate || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("hall")}:
                        </span>{" "}
                        {detail?.row.HallName || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("account")}:
                        </span>{" "}
                        {detail?.row.AccountNumber || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("loan_id")}:
                        </span>{" "}
                        {detail?.row.LoanNumber || ""} <br />
                        <span className="mx-2 font-weight-bolder">
                          {t("amount")}:
                        </span>{" "}
                        {numeral(detail?.row.LoanAmount).format(
                          "0,0.[00000]"
                        ) || ""}{" "}
                        <br />
                      </p>
                    )}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn bg-gradient-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <button
              type="button"
              ref={modalButton}
              className="btn bg-gradient-warning"
              data-bs-toggle="modal"
              data-bs-target="#detailsModal"
              style={{ display: "none" }}
            >
              Launch demo modal
            </button>
          </div>
          <footer className="footer py-5">
            <div className="container">
              <div className="row">
                <div className="col-12 mx-auto text-center mt-1 text-xxs">
                  {`Version ${process.env.REACT_APP_VERSION} ${process.env.REACT_APP_BUILD} `}
                </div>
                <div className="col-8 mx-auto text-center mt-1">
                  <p className="mb-0 text-xs text-secondary">
                    Copyright ©{" " + new Date().getFullYear() + " "}
                    OGTING.com. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>

      <div
        className="modal fade"
        id="settingsModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="settingsModal"
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        data-bs-focus="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-md"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body p-0">
              <div className="card card-plain">
                <div className="card-header pb-0 text-left">
                  <h5 className="">{t("change-password")}</h5>
                </div>
                <div className="card-body">
                  <form role="form text-left">
                    <div className="input-group input-group-lg input-group-static py-0">
                      <label className="w-100">{t("password")}</label>
                      <input
                        type={visible ? "text" : "password"}
                        className={`form-control border border-1 rounded-start ps-2 ${
                          visible ? "fs-6" : "fs-5"
                        }`}
                        value={newPass}
                        onChange={(e) => setNewPass(e.currentTarget.value)}
                      />
                      <span className="p-2 m-2" style={{ zIndex: 100 }}>
                        {visible ? (
                          <i
                            className="fas fa-eye fs-6"
                            onClick={() => setVisible(visible ? false : true)}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash fs-6"
                            onClick={() => setVisible(visible ? false : true)}
                          ></i>
                        )}
                      </span>
                    </div>
                    <div className="input-group input-group-lg input-group-static my-3">
                      <label className="w-100">{t("verify-password")}</label>
                      <input
                        type={visible ? "text" : "password"}
                        className={`form-control border border-1 rounded-start ps-2 ${
                          visible ? "fs-6" : "fs-5"
                        }`}
                        value={verifyPass}
                        onChange={(e) => setVerifyPass(e.currentTarget.value)}
                      />
                      <span className="p-2 m-2" style={{ zIndex: 100 }}>
                        {visible ? (
                          <i
                            className="fas fa-eye fs-6"
                            onClick={() => setVisible(visible ? false : true)}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash fs-6"
                            onClick={() => setVisible(visible ? false : true)}
                          ></i>
                        )}
                      </span>
                    </div>
                    <div className="row d-flex text-center ">
                      <div className="col my-2 text-center">
                        <button
                          type="button"
                          disabled={loading}
                          className="mx-1 w-100 btn btn-round bg-gradient-warning btn-lg mb-0"
                          onClick={updatePassword}
                        >
                          {t("save")}
                        </button>
                      </div>
                      <div className="col my-2 text-center">
                        <button
                          type="button"
                          disabled={loading}
                          ref={cancelButton}
                          className="mx-1 w-100 btn btn-round bg-gradient-dark btn-lg mb-0"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setNewPass("");
                            setVerifyPass("");
                          }}
                        >
                          {t("cancel")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
