/* eslint-disable */
import React, { useRef, useState, useEffect, useCallback, ChangeEvent } from "react";
import "flatpickr/dist/plugins/monthSelect/style.css";
import "flatpickr/dist/themes/light.css";

import Flatpickr from "react-flatpickr";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js";

import {
  ClassAttributes,
  ReactChild,
  ReactFragment,
  ReactPortal,
  TdHTMLAttributes
} from "react";

import {
  useTable,
  useSortBy,
  useGlobalFilter,
  Column,
  Row,
  IdType,
  usePagination,
} from "react-table";

import { matchSorter } from "match-sorter";
import numeral from "numeral";
import { useTranslation } from "react-i18next";


interface TableProps<T extends object> {
  name?:string,
  columns: Column<T>[];
  data: T[];
  hiddenCols: string[] | undefined;
  filters: string[];
  loading: boolean;
  callback?:Function
}

// @ts-ignore
const TableFilter = ({ name, columns, data, hiddenCols, filters, loading, callback }: TableProps<T>) => {

  const { t } = useTranslation();
  // @ts-ignore
  const ourGlobalFilterFunction = useCallback(
    // @ts-ignore
    (rows: Row<T>[], ids: IdType<T>[], query: string) => {
      return matchSorter(rows, query, {
        keys: filters.map((columnName) => `values.${columnName}`),
      });
    },
    [filters]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    allColumns,
    getToggleHideAllColumnsProps,
    // @ts-ignore
    setGlobalFilter,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageIndex, pageSize },
    // @ts-ignore
  } = useTable<T>(
    {
      columns,
      data,
      // @ts-ignore
      initialState: { pageIndex: 0, pageSize: 50, hiddenColumns: hiddenCols },
      globalFilter: ourGlobalFilterFunction,
      show: false
    },
    useGlobalFilter,
    //useSortBy,
    usePagination
  );

  const GetCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const GetCurrentYearMonth = () => {
    const currDate = new Date();
    const currentMonth: string = (currDate.getUTCMonth() + 1 > 9) ? (currDate.getUTCMonth() + 1).toString() : "0" + (currDate.getUTCMonth() + 1).toString();
    const dateTime: string = currDate.getUTCFullYear().toString() + '-' + currentMonth;
    return dateTime;
  };

  const [yearMonth, setYearMonth] = useState(GetCurrentYearMonth());
  const [filter, setFilter] = useState("");
  const refs = useRef(new Array(allColumns.length));

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };
  useEffect(() => {
    setGlobalFilter(filter);
  }, [filter, setGlobalFilter]);

  return (
    <div className="table-responsive p-0">
       <div className="dataTable-wrapper dataTable-loading no-footer sortable fixed-height fixed-columns">
         {
          (data && data.length >= 0) &&
          <div
            className="dataTable-top"
            style={{ padding: "0.5rem 1.5rem 0.5rem 1.5rem" }}
          >
            <div className="dataTable-dropdown">
              <label>
                <select
                  className="dataTable-selector"
                  value={pageSize}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>
                &nbsp; entries per page
              </label>
            </div>
          </div>
        }
       <table
          className="table align-items-center mb-0"
          id="datatable-search"
          {...getTableProps()}
        >
          <thead className="thead-light">
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {index > 0 &&
                  headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(
                        //column.getSortByToggleProps()
                      )}
                      className="align-middle text-center text-uppercase text-secondary text-sm font-weight-bolder"
                    >
                      {column.render("Header")} &nbsp;
                    </th>
                  ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row: Row<object>, i: any) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(
                      (
                        cell: {
                          column: any;
                          getCellProps: () => JSX.IntrinsicAttributes &
                            ClassAttributes<HTMLTableDataCellElement> &
                            TdHTMLAttributes<HTMLTableDataCellElement>;
                          render: (
                            arg0: string
                          ) =>
                            | boolean
                            | ReactFragment
                            | ReactChild
                            | ReactPortal
                            | null
                            | undefined;
                          value: any
                        },
                        index: number
                      ) => {
                        return (
                          <td
                            className="align-middle text-center text-sm"
                            onClick={(e)=>{
                              if (typeof callback == 'function') { 
                                callback({type: name, row: row.values}); 
                              }
                            }}
                            {...cell.getCellProps()}
                          >
                            {(() => {
                              if(cell.column.id === "TransactionAmount") {
                                return (numeral(Number(cell.value)).format(
                                  "0,0.[00000]"
                                ) || "")
                              }

                              if(cell.column.id === "Balance") {
                                return (numeral(Number(cell.value)).format(
                                  "0,0.[00000]"
                                ) || "")
                              }

                              if(cell.column.id === "LoanAmount") {
                                return (numeral(Number(cell.value)).format(
                                  "0,0.[00000]"
                                ) || "")
                              }

                              return cell.render("Cell")
                            })()}
                          </td>
                        );
                      }
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="align-middle text-center text-sm" colSpan={headerGroups[1].headers?.length}>
                  {loading ? "Loading..." : "No entries found"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="dataTable-bottom">
          <div className="dataTable-info">
          <label>
              {t("showing")} {pageIndex + 1} {t("to")} {pageCount} {t("of")} {pageSize}{" "}{t("entries")}
            </label>
          </div>
          <nav className="dataTable-pagination">
            <ul className="pagination pagination-warning">
              <li className="page-item">
                <button className='page-link' onClick={() => gotoPage(0)} disabled={loading ? true : !canPreviousPage}>
                  <i className="fas fa-angle-double-left"></i>
                </button>{" "}
              </li>
              <li className="page-item">
                 <button className='page-link' onClick={() => previousPage()} disabled={loading ? true : !canPreviousPage}>
                 <i className="fas fa-angle-left"></i>
                </button>
              </li>
              <li className="page-item ">
                <button className='page-link' onClick={() => nextPage()} disabled={loading ? true : !canNextPage}>
                <i className="fas fa-angle-right"></i>
                </button>
              </li>
              <li className="page-item ">
                <button className='page-link text-center' onClick={() => gotoPage(pageCount - 1)} disabled={loading ? true : !canNextPage} >
                  <i className="fas fa-angle-double-right"></i>
                </button>
              </li>
            </ul>
          </nav>
        </div>
    </div>
  );
};

export default TableFilter;
